/* eslint-disable */
import { NotificationManager } from '../components/common/react-notifications';
import { BASE_URL } from './Config';
import urls from './Urls';
import { api } from './Utils';

// const USER_SECRETKEY = JSON.parse(localStorage.getItem('userData')).secretKey;
// const USER_ID = JSON.parse(localStorage.getItem('userData')).id;
// const USER_ROLE = JSON.parse(
//   localStorage.getItem('userData')
// ).role.toLowerCase();

const getDesignations = async () => {
  try {
    const res = await api.get(
      `${BASE_URL}/api/${JSON.parse(
        localStorage.getItem('userData')
      ).role.toLowerCase()}/${urls.getDesignations}/${
        JSON.parse(localStorage.getItem('userData')).secretKey
      }/${JSON.parse(localStorage.getItem('userData')).id}`
    );
    if (res.data) {
      return Promise.resolve(res.data);
    } else {
      return Promise.reject(res.data);
    }
  } catch (error) {
    if (error.response) {
      NotificationManager.warning(
        error.response.data.message,
        'Error',
        3000,
        null,
        null,
        ''
      );
    } else if (error.request) {
      NotificationManager.warning(
        error.request.toString(),
        'Error',
        3000,
        null,
        null,
        ''
      );
    } else {
      NotificationManager.warning(error.message, 'Error', 3000, null, null, '');
    }
  }
};

const findManagersOfOrgnaization = async (body, orgId) => {
  try {
    const res = await api.post(
      `${BASE_URL}/api/owner/${urls.findManagersOfOrgnaization}/${
        JSON.parse(localStorage.getItem('userData')).secretKey
      }/${JSON.parse(localStorage.getItem('userData')).id}/${orgId}`,
      body
    );
    if (res.data) {
      return Promise.resolve(res.data);
    } else {
      return Promise.reject(res.data);
    }
  } catch (error) {
    if (error.response) {
      NotificationManager.warning(
        error.response.data.message,
        'Error',
        3000,
        null,
        null,
        ''
      );
    } else if (error.request) {
      NotificationManager.warning(
        error.request.toString(),
        'Error',
        3000,
        null,
        null,
        ''
      );
    } else {
      NotificationManager.warning(error.message, 'Error', 3000, null, null, '');
    }
  }
};

const findDesignationsOfOrgnaization = async (body, orgId) => {
  try {
    const res = await api.post(
      `${BASE_URL}/api/owner/${urls.findDesignationsOfOrgnaization}/${
        JSON.parse(localStorage.getItem('userData')).secretKey
      }/${JSON.parse(localStorage.getItem('userData')).id}/${orgId}`,
      body
    );
    if (res.data) {
      return Promise.resolve(res.data);
    } else {
      return Promise.reject(res.data);
    }
  } catch (error) {
    if (error.response) {
      NotificationManager.warning(
        error.response.data.message,
        'Error',
        3000,
        null,
        null,
        ''
      );
    } else if (error.request) {
      NotificationManager.warning(
        error.request.toString(),
        'Error',
        3000,
        null,
        null,
        ''
      );
    } else {
      NotificationManager.warning(error.message, 'Error', 3000, null, null, '');
    }
  }
};

const findEmployeesOfOrgnaization = async (body, orgId) => {
  try {
    const res = await api.post(
      `${BASE_URL}/api/owner/${urls.findEmployeesOfOrgnaization}/${
        JSON.parse(localStorage.getItem('userData')).secretKey
      }/${JSON.parse(localStorage.getItem('userData')).id}/${orgId}`,
      body
    );
    if (res.data) {
      return Promise.resolve(res.data);
    } else {
      return Promise.reject(res.data);
    }
  } catch (error) {
    if (error.response) {
      NotificationManager.warning(
        error.response.data.message,
        'Error',
        3000,
        null,
        null,
        ''
      );
    } else if (error.request) {
      NotificationManager.warning(
        error.request.toString(),
        'Error',
        3000,
        null,
        null,
        ''
      );
    } else {
      NotificationManager.warning(error.message, 'Error', 3000, null, null, '');
    }
  }
};

const getAllEmployee = async () => {
  try {
    const res = await api.get(
      `${BASE_URL}/api/owner/${urls.getAllEmployees}/${
        JSON.parse(localStorage.getItem('userData')).secretKey
      }/${JSON.parse(localStorage.getItem('userData')).id}`
    );
    if (res.data) {
      return Promise.resolve(res.data);
    } else {
      return Promise.reject(res.data);
    }
  } catch (error) {
    if (error.response) {
      NotificationManager.warning(
        error.response.data.message,
        'Error',
        3000,
        null,
        null,
        ''
      );
    } else if (error.request) {
      NotificationManager.warning(
        error.request.toString(),
        'Error',
        3000,
        null,
        null,
        ''
      );
    } else {
      NotificationManager.warning(error.message, 'Error', 3000, null, null, '');
    }
  }
};

const addManagerToOrganization = async (body, orgId) => {
  try {
    const res = await api.post(
      `${BASE_URL}/api/owner/${urls.addManagerToOrganization}/${
        JSON.parse(localStorage.getItem('userData')).secretKey
      }/${JSON.parse(localStorage.getItem('userData')).id}/${orgId}`,
      body
    );
    if (res.data) {
      return Promise.resolve(res.data);
    } else {
      return Promise.reject(res.data);
    }
  } catch (error) {
    if (error.response) {
      NotificationManager.warning(
        error.response.data.message,
        'Error',
        3000,
        null,
        null,
        ''
      );
    } else if (error.request) {
      NotificationManager.warning(
        error.request.toString(),
        'Error',
        3000,
        null,
        null,
        ''
      );
    } else {
      NotificationManager.warning(error.message, 'Error', 3000, null, null, '');
    }
  }
};

const removeManagerFromOrganization = async (body, orgId) => {
  try {
    const res = await api.post(
      `${BASE_URL}/api/owner/${urls.removeManagerFromOrganization}/${
        JSON.parse(localStorage.getItem('userData')).secretKey
      }/${JSON.parse(localStorage.getItem('userData')).id}/${orgId}`,
      body
    );
    if (res.data) {
      return Promise.resolve(res.data);
    } else {
      return Promise.reject(res.data);
    }
  } catch (error) {
    if (error.response) {
      NotificationManager.warning(
        error.response.data.message,
        'Error',
        3000,
        null,
        null,
        ''
      );
    } else if (error.request) {
      NotificationManager.warning(
        error.request.toString(),
        'Error',
        3000,
        null,
        null,
        ''
      );
    } else {
      NotificationManager.warning(error.message, 'Error', 3000, null, null, '');
    }
  }
};

const getAllDesignations = async () => {
  try {
    const res = await api.get(
      `${BASE_URL}/api/owner/${urls.getAllDesignations}/${
        JSON.parse(localStorage.getItem('userData')).secretKey
      }/${JSON.parse(localStorage.getItem('userData')).id}`
    );
    if (res.data) {
      return Promise.resolve(res.data);
    } else {
      return Promise.reject(res.data);
    }
  } catch (error) {
    if (error.response) {
      NotificationManager.warning(
        error.response.data.message,
        'Error',
        3000,
        null,
        null,
        ''
      );
    } else if (error.request) {
      NotificationManager.warning(
        error.request.toString(),
        'Error',
        3000,
        null,
        null,
        ''
      );
    } else {
      NotificationManager.warning(error.message, 'Error', 3000, null, null, '');
    }
  }
};

const getDesignationsByOrgnaization = async (orgId) => {
  try {
    const res = await api.get(
      `${BASE_URL}/api/${JSON.parse(
        localStorage.getItem('userData')
      ).role.toLowerCase()}/${urls.getDesignationsByOrgnaization}/${
        JSON.parse(localStorage.getItem('userData')).secretKey
      }/${JSON.parse(localStorage.getItem('userData')).id}/${orgId}`
    );
    if (res.data) {
      return Promise.resolve(res.data);
    } else {
      return Promise.reject(res.data);
    }
  } catch (error) {
    if (error.response) {
      NotificationManager.warning(
        error.response.data.message,
        'Error',
        3000,
        null,
        null,
        ''
      );
    } else if (error.request) {
      NotificationManager.warning(
        error.request.toString(),
        'Error',
        3000,
        null,
        null,
        ''
      );
    } else {
      NotificationManager.warning(error.message, 'Error', 3000, null, null, '');
    }
  }
};

const getEmployeesByDesignationAndByOrganization = async (
  desgId,
  orgId,
  body
) => {
  try {
    const res = await api.post(
      `${BASE_URL}/api/${JSON.parse(
        localStorage.getItem('userData')
      ).role.toLowerCase()}/${
        urls.getEmployeesByDesignationAndByOrganization
      }/${JSON.parse(localStorage.getItem('userData')).secretKey}/${
        JSON.parse(localStorage.getItem('userData')).id
      }/${desgId}/${orgId}`,
      body
    );
    if (res.data) {
      console.log('Employees by designation and organization : ', res.data);

      return Promise.resolve(res.data);
    } else {
      return Promise.reject(res.data);
    }
  } catch (error) {
    if (error.response) {
      NotificationManager.warning(
        error.response.data.message,
        'Error',
        3000,
        null,
        null,
        ''
      );
    } else if (error.request) {
      NotificationManager.warning(
        error.request.toString(),
        'Error',
        3000,
        null,
        null,
        ''
      );
    } else {
      NotificationManager.warning(error.message, 'Error', 3000, null, null, '');
    }
  }
};

const getAllEmployeesByDesignationAndByOrganization = async (desgId, orgId) => {
  try {
    const res = await api.get(
      `${BASE_URL}/api/${JSON.parse(
        localStorage.getItem('userData')
      ).role.toLowerCase()}/${
        urls.getAllEmployeesByDesignationAndByOrganization
      }/${JSON.parse(localStorage.getItem('userData')).secretKey}/${
        JSON.parse(localStorage.getItem('userData')).id
      }/${desgId}/${orgId}`
    );
    if (res.data) {
      console.log('Employees by designation and organization : ', res.data);

      return Promise.resolve(res.data);
    } else {
      return Promise.reject(res.data);
    }
  } catch (error) {
    if (error.response) {
      NotificationManager.warning(
        error.response.data.message,
        'Error',
        3000,
        null,
        null,
        ''
      );
    } else if (error.request) {
      NotificationManager.warning(
        error.request.toString(),
        'Error',
        3000,
        null,
        null,
        ''
      );
    } else {
      NotificationManager.warning(error.message, 'Error', 3000, null, null, '');
    }
  }
};

const addDesignationToOrganization = async (body, orgId) => {
  try {
    const res = await api.post(
      `${BASE_URL}/api/owner/${urls.addDesignationToOrganization}/${
        JSON.parse(localStorage.getItem('userData')).secretKey
      }/${JSON.parse(localStorage.getItem('userData')).id}/${orgId}`,
      body
    );
    if (res.data) {
      return Promise.resolve(res.data);
    } else {
      return Promise.reject(res.data);
    }
  } catch (error) {
    if (error.response) {
      NotificationManager.warning(
        error.response.data.message,
        'Error',
        3000,
        null,
        null,
        ''
      );
    } else if (error.request) {
      NotificationManager.warning(
        error.request.toString(),
        'Error',
        3000,
        null,
        null,
        ''
      );
    } else {
      NotificationManager.warning(error.message, 'Error', 3000, null, null, '');
    }
  }
};

const removeDesignationFromOrganization = async (body, orgId) => {
  try {
    const res = await api.post(
      `${BASE_URL}/api/owner/${urls.removeDesignationFromOrganization}/${
        JSON.parse(localStorage.getItem('userData')).secretKey
      }/${JSON.parse(localStorage.getItem('userData')).id}/${orgId}`,
      body
    );
    if (res.data) {
      return Promise.resolve(res.data);
    } else {
      return Promise.reject(res.data);
    }
  } catch (error) {
    if (error.response) {
      NotificationManager.warning(
        error.response.data.message,
        'Error',
        3000,
        null,
        null,
        ''
      );
    } else if (error.request) {
      NotificationManager.warning(
        error.request.toString(),
        'Error',
        3000,
        null,
        null,
        ''
      );
    } else {
      NotificationManager.warning(error.message, 'Error', 3000, null, null, '');
    }
  }
};

const addEmployeeToOrganization = async (body, orgId) => {
  try {
    const res = await api.post(
      `${BASE_URL}/api/owner/${urls.addEmployeeToOrganization}/${
        JSON.parse(localStorage.getItem('userData')).secretKey
      }/${JSON.parse(localStorage.getItem('userData')).id}/${orgId}`,
      body
    );
    if (res.data) {
      return Promise.resolve(res.data);
    } else {
      return Promise.reject(res.data);
    }
  } catch (error) {
    if (error.response) {
      NotificationManager.warning(
        error.response.data.message,
        'Error',
        3000,
        null,
        null,
        ''
      );
    } else if (error.request) {
      NotificationManager.warning(
        error.request.toString(),
        'Error',
        3000,
        null,
        null,
        ''
      );
    } else {
      NotificationManager.warning(error.message, 'Error', 3000, null, null, '');
    }
  }
};

const removeEmployeeFromOrganization = async (body, orgId) => {
  try {
    const res = await api.post(
      `${BASE_URL}/api/owner/${urls.removeEmployeeFromOrganization}/${
        JSON.parse(localStorage.getItem('userData')).secretKey
      }/${JSON.parse(localStorage.getItem('userData')).id}/${orgId}`,
      body
    );
    if (res.data) {
      return Promise.resolve(res.data);
    } else {
      return Promise.reject(res.data);
    }
  } catch (error) {
    if (error.response) {
      NotificationManager.warning(
        error.response.data.message,
        'Error',
        3000,
        null,
        null,
        ''
      );
    } else if (error.request) {
      NotificationManager.warning(
        error.request.toString(),
        'Error',
        3000,
        null,
        null,
        ''
      );
    } else {
      NotificationManager.warning(error.message, 'Error', 3000, null, null, '');
    }
  }
};

const findOrganizations = async (body) => {
  try {
    const res = await api.post(
      `${BASE_URL}/api/owner/${urls.findOrganizations}/${
        JSON.parse(localStorage.getItem('userData')).secretKey
      }/${JSON.parse(localStorage.getItem('userData')).id}`,
      body
    );
    if (res.data) {
      return Promise.resolve(res.data);
    } else {
      return Promise.reject(res.data);
    }
  } catch (error) {
    if (error.response) {
      NotificationManager.warning(
        error.response.data.message,
        'Error',
        3000,
        null,
        null,
        ''
      );
    } else if (error.request) {
      NotificationManager.warning(
        error.request.toString(),
        'Error',
        3000,
        null,
        null,
        ''
      );
    } else {
      NotificationManager.warning(error.message, 'Error', 3000, null, null, '');
    }
  }
};

const addOrganizations = async (body) => {
  try {
    const res = await api.post(
      `${BASE_URL}/api/owner/${urls.addOrganization}/${
        JSON.parse(localStorage.getItem('userData')).secretKey
      }/${JSON.parse(localStorage.getItem('userData')).id}`,
      body
    );
    if (res.data) {
      return Promise.resolve(res.data);
    } else {
      return Promise.reject(res.data);
    }
  } catch (error) {
    if (error.response) {
      NotificationManager.warning(
        error.response.data.message,
        'Error',
        3000,
        null,
        null,
        ''
      );
    } else if (error.request) {
      NotificationManager.warning(
        error.request.toString(),
        'Error',
        3000,
        null,
        null,
        ''
      );
    } else {
      NotificationManager.warning(error.message, 'Error', 3000, null, null, '');
    }
  }
};

const getOrganization = async (id) => {
  try {
    const res = await api.get(
      `${BASE_URL}/api/owner/${urls.getOrganizationDetails}/${
        JSON.parse(localStorage.getItem('userData')).secretKey
      }/${JSON.parse(localStorage.getItem('userData')).id}/${id}`
    );
    if (res.data) {
      return Promise.resolve(res.data);
    } else {
      return Promise.reject(res.data);
    }
  } catch (error) {
    if (error.response) {
      NotificationManager.warning(
        error.response.data.message,
        'Error',
        3000,
        null,
        null,
        ''
      );
    } else if (error.request) {
      NotificationManager.warning(
        error.request.toString(),
        'Error',
        3000,
        null,
        null,
        ''
      );
    } else {
      NotificationManager.warning(error.message, 'Error', 3000, null, null, '');
    }
  }
};

const updateOrganizations = async (body) => {
  try {
    const res = await api.post(
      `${BASE_URL}/api/owner/${urls.updateOrganization}/${
        JSON.parse(localStorage.getItem('userData')).secretKey
      }/${JSON.parse(localStorage.getItem('userData')).id}`,
      body
    );
    if (res.data) {
      return Promise.resolve(res.data);
    } else {
      return Promise.reject(res.data);
    }
  } catch (error) {
    if (error.response) {
      NotificationManager.warning(
        error.response.data.message,
        'Error',
        3000,
        null,
        null,
        ''
      );
    } else if (error.request) {
      NotificationManager.warning(
        error.request.toString(),
        'Error',
        3000,
        null,
        null,
        ''
      );
    } else {
      NotificationManager.warning(error.message, 'Error', 3000, null, null, '');
    }
  }
};

const deleteOrganization = async (id) => {
  try {
    const res = await api.get(
      `${BASE_URL}/api/owner/${urls.deleteOrganization}/${
        JSON.parse(localStorage.getItem('userData')).secretKey
      }/${JSON.parse(localStorage.getItem('userData')).id}/${id}`
    );
    if (res.data) {
      return Promise.resolve(res.data);
    } else {
      return Promise.reject(res.data);
    }
  } catch (error) {
    if (error.response) {
      NotificationManager.warning(
        error.response.data.message,
        'Error',
        3000,
        null,
        null,
        ''
      );
    } else if (error.request) {
      NotificationManager.warning(
        error.request.toString(),
        'Error',
        3000,
        null,
        null,
        ''
      );
    } else {
      NotificationManager.warning(error.message, 'Error', 3000, null, null, '');
    }
  }
};

const findDesignations = async (body) => {
  try {
    const res = await api.post(
      `${BASE_URL}/api/owner/${urls.findDesignations}/${
        JSON.parse(localStorage.getItem('userData')).secretKey
      }/${JSON.parse(localStorage.getItem('userData')).id}`,
      body
    );
    if (res.data) {
      return Promise.resolve(res.data);
    } else {
      return Promise.reject(res.data);
    }
  } catch (error) {
    if (error.response) {
      NotificationManager.warning(
        error.response.data.message,
        'Error',
        3000,
        null,
        null,
        ''
      );
    } else if (error.request) {
      NotificationManager.warning(
        error.request.toString(),
        'Error',
        3000,
        null,
        null,
        ''
      );
    } else {
      NotificationManager.warning(error.message, 'Error', 3000, null, null, '');
    }
  }
};

const addDesignation = async (body) => {
  try {
    const res = await api.post(
      `${BASE_URL}/api/owner/${urls.addDesignation}/${
        JSON.parse(localStorage.getItem('userData')).secretKey
      }/${JSON.parse(localStorage.getItem('userData')).id}`,
      body
    );
    if (res.data) {
      return Promise.resolve(res.data);
    } else {
      return Promise.reject(res.data);
    }
  } catch (error) {
    if (error.response) {
      NotificationManager.warning(
        error.response.data.message,
        'Error',
        3000,
        null,
        null,
        ''
      );
    } else if (error.request) {
      NotificationManager.warning(
        error.request.toString(),
        'Error',
        3000,
        null,
        null,
        ''
      );
    } else {
      NotificationManager.warning(error.message, 'Error', 3000, null, null, '');
    }
  }
};

const getDesignation = async (id) => {
  try {
    const res = await api.get(
      `${BASE_URL}/api/owner/${urls.getDesignationDetails}/${
        JSON.parse(localStorage.getItem('userData')).secretKey
      }/${JSON.parse(localStorage.getItem('userData')).id}/${id}`
    );
    if (res.data) {
      return Promise.resolve(res.data);
    } else {
      return Promise.reject(res.data);
    }
  } catch (error) {
    if (error.response) {
      NotificationManager.warning(
        error.response.data.message,
        'Error',
        3000,
        null,
        null,
        ''
      );
    } else if (error.request) {
      NotificationManager.warning(
        error.request.toString(),
        'Error',
        3000,
        null,
        null,
        ''
      );
    } else {
      NotificationManager.warning(error.message, 'Error', 3000, null, null, '');
    }
  }
};

const updateDesignation = async (body, id) => {
  try {
    const res = await api.post(
      `${BASE_URL}/api/owner/${urls.updateDesignation}/${
        JSON.parse(localStorage.getItem('userData')).secretKey
      }/${JSON.parse(localStorage.getItem('userData')).id}/${id}`,
      body
    );
    if (res.data) {
      return Promise.resolve(res.data);
    } else {
      return Promise.reject(res.data);
    }
  } catch (error) {
    if (error.response) {
      NotificationManager.warning(
        error.response.data.message,
        'Error',
        3000,
        null,
        null,
        ''
      );
    } else if (error.request) {
      NotificationManager.warning(
        error.request.toString(),
        'Error',
        3000,
        null,
        null,
        ''
      );
    } else {
      NotificationManager.warning(error.message, 'Error', 3000, null, null, '');
    }
  }
};

const deleteDesignations = async (id) => {
  try {
    const res = await api.get(
      `${BASE_URL}/api/owner/${urls.deleteDesignation}/${
        JSON.parse(localStorage.getItem('userData')).secretKey
      }/${JSON.parse(localStorage.getItem('userData')).id}/${id}`
    );
    if (res.data) {
      return Promise.resolve(res.data);
    } else {
      return Promise.reject(res.data);
    }
  } catch (error) {
    if (error.response) {
      NotificationManager.warning(
        error.response.data.message,
        'Error',
        3000,
        null,
        null,
        ''
      );
    } else if (error.request) {
      NotificationManager.warning(
        error.request.toString(),
        'Error',
        3000,
        null,
        null,
        ''
      );
    } else {
      NotificationManager.warning(error.message, 'Error', 3000, null, null, '');
    }
  }
};

const findEmployees = async (body) => {
  try {
    const res = await api.post(
      `${BASE_URL}/api/owner/${urls.findEmployees}/${
        JSON.parse(localStorage.getItem('userData')).secretKey
      }/${JSON.parse(localStorage.getItem('userData')).id}`,
      body
    );
    if (res.data) {
      return Promise.resolve(res.data);
    } else {
      return Promise.reject(res.data);
    }
  } catch (error) {
    if (error.response) {
      NotificationManager.warning(
        error.response.data.message,
        'Error',
        3000,
        null,
        null,
        ''
      );
    } else if (error.request) {
      NotificationManager.warning(
        error.request.toString(),
        'Error',
        3000,
        null,
        null,
        ''
      );
    } else {
      NotificationManager.warning(error.message, 'Error', 3000, null, null, '');
    }
  }
};

const addEmployee = async (body) => {
  try {
    const res = await api.post(
      `${BASE_URL}/api/owner/${urls.addEmployee}/${
        JSON.parse(localStorage.getItem('userData')).secretKey
      }/${JSON.parse(localStorage.getItem('userData')).id}`,
      body
    );
    if (res.data) {
      return Promise.resolve(res.data);
    } else {
      return Promise.reject(res.data);
    }
  } catch (error) {
    if (error.response) {
      NotificationManager.warning(
        error.response.data.message,
        'Error',
        3000,
        null,
        null,
        ''
      );
    } else if (error.request) {
      NotificationManager.warning(
        error.request.toString(),
        'Error',
        3000,
        null,
        null,
        ''
      );
    } else {
      NotificationManager.warning(error.message, 'Error', 3000, null, null, '');
    }
  }
};

const getEmployee = async (id) => {
  try {
    const res = await api.get(
      `${BASE_URL}/api/owner/${urls.getEmployeeDetails}/${
        JSON.parse(localStorage.getItem('userData')).secretKey
      }/${JSON.parse(localStorage.getItem('userData')).id}/${id}`
    );
    if (res.data) {
      return Promise.resolve(res.data);
    } else {
      return Promise.reject(res.data);
    }
  } catch (error) {
    if (error.response) {
      NotificationManager.warning(
        error.response.data.message,
        'Error',
        3000,
        null,
        null,
        ''
      );
    } else if (error.request) {
      NotificationManager.warning(
        error.request.toString(),
        'Error',
        3000,
        null,
        null,
        ''
      );
    } else {
      NotificationManager.warning(error.message, 'Error', 3000, null, null, '');
    }
  }
};

const updateEmployee = async (body) => {
  try {
    const res = await api.post(
      `${BASE_URL}/api/owner/${urls.updateEmployee}/${
        JSON.parse(localStorage.getItem('userData')).secretKey
      }/${JSON.parse(localStorage.getItem('userData')).id}`,
      body
    );
    if (res.data) {
      return Promise.resolve(res.data);
    } else {
      return Promise.reject(res.data);
    }
  } catch (error) {
    if (error.response) {
      NotificationManager.warning(
        error.response.data.message,
        'Error',
        3000,
        null,
        null,
        ''
      );
    } else if (error.request) {
      NotificationManager.warning(
        error.request.toString(),
        'Error',
        3000,
        null,
        null,
        ''
      );
    } else {
      NotificationManager.warning(error.message, 'Error', 3000, null, null, '');
    }
  }
};

const deleteEmployee = async (id) => {
  try {
    const res = await api.get(
      `${BASE_URL}/api/owner/${urls.deleteEmployee}/${
        JSON.parse(localStorage.getItem('userData')).secretKey
      }/${JSON.parse(localStorage.getItem('userData')).id}/${id}`
    );
    if (res.data) {
      return Promise.resolve(res.data);
    } else {
      return Promise.reject(res.data);
    }
  } catch (error) {
    if (error.response) {
      NotificationManager.warning(
        error.response.data.message,
        'Error',
        3000,
        null,
        null,
        ''
      );
    } else if (error.request) {
      NotificationManager.warning(
        error.request.toString(),
        'Error',
        3000,
        null,
        null,
        ''
      );
    } else {
      NotificationManager.warning(error.message, 'Error', 3000, null, null, '');
    }
  }
};

const getShiftTemplates = async () => {
  try {
    const res = await api.get(
      `${BASE_URL}/api/${JSON.parse(
        localStorage.getItem('userData')
      ).role.toLowerCase()}/${urls.getShiftTemplates}/${
        JSON.parse(localStorage.getItem('userData')).secretKey
      }/${JSON.parse(localStorage.getItem('userData')).id}`
    );
    if (res.data) {
      return Promise.resolve(res.data);
    } else {
      return Promise.reject(res.data);
    }
  } catch (error) {
    if (error.response) {
      NotificationManager.warning(
        error.response.data.message,
        'Error',
        3000,
        null,
        null,
        ''
      );
    } else if (error.request) {
      NotificationManager.warning(
        error.request.toString(),
        'Error',
        3000,
        null,
        null,
        ''
      );
    } else {
      NotificationManager.warning(error.message, 'Error', 3000, null, null, '');
    }
  }
};

const getShiftTemplateDetail = async (tempId) => {
  try {
    const res = await api.get(
      `${BASE_URL}/api/${JSON.parse(
        localStorage.getItem('userData')
      ).role.toLowerCase()}/${urls.getShiftTemplateDetail}/${
        JSON.parse(localStorage.getItem('userData')).secretKey
      }/${JSON.parse(localStorage.getItem('userData')).id}/${tempId}`
    );
    if (res.data) {
      return Promise.resolve(res.data);
    } else {
      return Promise.reject(res.data);
    }
  } catch (error) {
    if (error.response) {
      NotificationManager.warning(
        error.response.data.message,
        'Error',
        3000,
        null,
        null,
        ''
      );
    } else if (error.request) {
      NotificationManager.warning(
        error.request.toString(),
        'Error',
        3000,
        null,
        null,
        ''
      );
    } else {
      NotificationManager.warning(error.message, 'Error', 3000, null, null, '');
    }
  }
};

const getAllOrganizations = async () => {
  try {
    const res = await api.get(
      `${BASE_URL}/api/${JSON.parse(
        localStorage.getItem('userData')
      ).role.toLowerCase()}/${urls.getAllOrganizations}/${
        JSON.parse(localStorage.getItem('userData')).secretKey
      }/${JSON.parse(localStorage.getItem('userData')).id}`
    );
    if (res.data) {
      return Promise.resolve(res.data);
    } else {
      return Promise.reject(res.data);
    }
  } catch (error) {
    if (error.response) {
      NotificationManager.warning(
        error.response.data.message,
        'Error',
        3000,
        null,
        null,
        ''
      );
    } else if (error.request) {
      NotificationManager.warning(
        error.request.toString(),
        'Error',
        3000,
        null,
        null,
        ''
      );
    } else {
      NotificationManager.warning(error.message, 'Error', 3000, null, null, '');
    }
  }
};

const getEmployeesByDesignation = async (desgId) => {
  try {
    const res = await api.get(
      `${BASE_URL}/api/${JSON.parse(
        localStorage.getItem('userData')
      ).role.toLowerCase()}/${urls.getEmployeesByDesignation}/${
        JSON.parse(localStorage.getItem('userData')).secretKey
      }/${JSON.parse(localStorage.getItem('userData')).id}/${desgId}`
    );
    if (res.data) {
      return Promise.resolve(res.data);
    } else {
      return Promise.reject(res.data);
    }
  } catch (error) {
    if (error.response) {
      NotificationManager.warning(
        error.response.data.message,
        'Error',
        3000,
        null,
        null,
        ''
      );
    } else if (error.request) {
      NotificationManager.warning(
        error.request.toString(),
        'Error',
        3000,
        null,
        null,
        ''
      );
    } else {
      NotificationManager.warning(error.message, 'Error', 3000, null, null, '');
    }
  }
};

const postShift = async (body) => {
  try {
    const res = await api.post(
      `${BASE_URL}/api/${JSON.parse(
        localStorage.getItem('userData')
      ).role.toLowerCase()}/${urls.postShift}/${
        JSON.parse(localStorage.getItem('userData')).secretKey
      }/${JSON.parse(localStorage.getItem('userData')).id}`,
      body
    );
    if (res.data) {
      return Promise.resolve(res.data);
    } else {
      return Promise.reject(res.data);
    }
  } catch (error) {
    if (error.response) {
      NotificationManager.warning(
        error.response.data.message,
        'Error',
        3000,
        null,
        null,
        ''
      );
    } else if (error.request) {
      NotificationManager.warning(
        error.request.toString(),
        'Error',
        3000,
        null,
        null,
        ''
      );
    } else {
      NotificationManager.warning(error.message, 'Error', 3000, null, null, '');
    }
  }
};

const findShiftTemplates = async (body) => {
  try {
    const res = await api.post(
      `${BASE_URL}/api/${JSON.parse(
        localStorage.getItem('userData')
      ).role.toLowerCase()}/${urls.findShiftTemplates}/${
        JSON.parse(localStorage.getItem('userData')).secretKey
      }/${JSON.parse(localStorage.getItem('userData')).id}`,
      body
    );
    if (res.data) {
      return Promise.resolve(res.data);
    } else {
      return Promise.reject(res.data);
    }
  } catch (error) {
    if (error.response) {
      NotificationManager.warning(
        error.response.data.message,
        'Error',
        3000,
        null,
        null,
        ''
      );
    } else if (error.request) {
      NotificationManager.warning(
        error.request.toString(),
        'Error',
        3000,
        null,
        null,
        ''
      );
    } else {
      NotificationManager.warning(error.message, 'Error', 3000, null, null, '');
    }
  }
};

const deleteShiftTemplate = async (tempId) => {
  try {
    const res = await api.get(
      `${BASE_URL}/api/${JSON.parse(
        localStorage.getItem('userData')
      ).role.toLowerCase()}/${urls.deleteShiftTemplate}/${
        JSON.parse(localStorage.getItem('userData')).secretKey
      }/${JSON.parse(localStorage.getItem('userData')).id}/${tempId}`
    );
    if (res.data) {
      return Promise.resolve(res.data);
    } else {
      return Promise.reject(res.data);
    }
  } catch (error) {
    if (error.response) {
      NotificationManager.warning(
        error.response.data.message,
        'Error',
        3000,
        null,
        null,
        ''
      );
    } else if (error.request) {
      NotificationManager.warning(
        error.request.toString(),
        'Error',
        3000,
        null,
        null,
        ''
      );
    } else {
      NotificationManager.warning(error.message, 'Error', 3000, null, null, '');
    }
  }
};

const getShifts = async (body) => {
  try {
    const res = await api.post(
      `${BASE_URL}/api/${JSON.parse(
        localStorage.getItem('userData')
      ).role.toLowerCase()}/${urls.getShifts}/${
        JSON.parse(localStorage.getItem('userData')).secretKey
      }/${JSON.parse(localStorage.getItem('userData')).id}`,
      body
    );
    if (res.data) {
      return Promise.resolve(res.data);
    } else {
      return Promise.reject(res.data);
    }
  } catch (error) {
    if (
      JSON.parse(localStorage.getItem('userData')).role.toLowerCase() !==
      'owner'
    ) {
      if (error.response) {
        NotificationManager.warning(
          error.response.data.message,
          'Error',
          3000,
          null,
          null,
          ''
        );
      } else if (error.request) {
        NotificationManager.warning(
          error.request.toString(),
          'Error',
          3000,
          null,
          null,
          ''
        );
      } else {
        NotificationManager.warning(
          error.message,
          'Error',
          3000,
          null,
          null,
          ''
        );
      }
    }
  }
};

const getShiftDetails = async (shiftId) => {
  try {
    const res = await api.get(
      `${BASE_URL}/api/${JSON.parse(
        localStorage.getItem('userData')
      ).role.toLowerCase()}/${urls.getShiftDetails}/${
        JSON.parse(localStorage.getItem('userData')).secretKey
      }/${JSON.parse(localStorage.getItem('userData')).id}/${shiftId}`
    );
    if (res.data) {
      return Promise.resolve(res.data);
    } else {
      return Promise.reject(res.data);
    }
  } catch (error) {
    if (error.response) {
      NotificationManager.warning(
        error.response.data.message,
        'Error',
        3000,
        null,
        null,
        ''
      );
    } else if (error.request) {
      NotificationManager.warning(
        error.request.toString(),
        'Error',
        3000,
        null,
        null,
        ''
      );
    } else {
      NotificationManager.warning(error.message, 'Error', 3000, null, null, '');
    }
  }
};

const getHistoryShifts = async (body) => {
  try {
    const res = await api.post(
      `${BASE_URL}/api/${JSON.parse(
        localStorage.getItem('userData')
      ).role.toLowerCase()}/${urls.getHistoryShifts}/${
        JSON.parse(localStorage.getItem('userData')).secretKey
      }/${JSON.parse(localStorage.getItem('userData')).id}`,
      body
    );
    if (res.data) {
      return Promise.resolve(res.data);
    } else {
      return Promise.reject(res.data);
    }
  } catch (error) {
    if (error.response) {
      NotificationManager.warning(
        error.response.data.message,
        'Error',
        3000,
        null,
        null,
        ''
      );
    } else if (error.request) {
      NotificationManager.warning(
        error.request.toString(),
        'Error',
        3000,
        null,
        null,
        ''
      );
    } else {
      NotificationManager.warning(error.message, 'Error', 3000, null, null, '');
    }
  }
};

const createShiftTemplate = async (body) => {
  try {
    const res = await api.post(
      `${BASE_URL}/api/${JSON.parse(
        localStorage.getItem('userData')
      ).role.toLowerCase()}/${urls.createShiftTemplate}/${
        JSON.parse(localStorage.getItem('userData')).secretKey
      }/${JSON.parse(localStorage.getItem('userData')).id}`,
      body
    );
    if (res.data) {
      return Promise.resolve(res.data);
    } else {
      return Promise.reject(res.data);
    }
  } catch (error) {
    if (error.response) {
      NotificationManager.warning(
        error.response.data.message,
        'Error',
        3000,
        null,
        null,
        ''
      );
    } else if (error.request) {
      NotificationManager.warning(
        error.request.toString(),
        'Error',
        3000,
        null,
        null,
        ''
      );
    } else {
      NotificationManager.warning(error.message, 'Error', 3000, null, null, '');
    }
  }
};

const updateShiftTemplate = async (body, tempId) => {
  try {
    const res = await api.post(
      `${BASE_URL}/api/${JSON.parse(
        localStorage.getItem('userData')
      ).role.toLowerCase()}/${urls.updateShiftTemplate}/${
        JSON.parse(localStorage.getItem('userData')).secretKey
      }/${JSON.parse(localStorage.getItem('userData')).id}/${tempId}`,
      body
    );
    if (res.data) {
      return Promise.resolve(res.data);
    } else {
      return Promise.reject(res.data);
    }
  } catch (error) {
    if (error.response) {
      NotificationManager.warning(
        error.response.data.message,
        'Error',
        3000,
        null,
        null,
        ''
      );
    } else if (error.request) {
      NotificationManager.warning(
        error.request.toString(),
        'Error',
        3000,
        null,
        null,
        ''
      );
    } else {
      NotificationManager.warning(error.message, 'Error', 3000, null, null, '');
    }
  }
};

const getUserDetails = async () => {
  try {
    const res = await api.get(
      `${BASE_URL}/api/${JSON.parse(
        localStorage.getItem('userData')
      ).role.toLowerCase()}/${urls.userDetails}/${
        JSON.parse(localStorage.getItem('userData')).secretKey
      }/${JSON.parse(localStorage.getItem('userData')).id}`
    );
    if (res.data) {
      return Promise.resolve(res.data);
    } else {
      return Promise.reject(res.data);
    }
  } catch (error) {
    if (error.response) {
      NotificationManager.warning(
        error.response.data.message,
        'Error',
        3000,
        null,
        null,
        ''
      );
    } else if (error.request) {
      NotificationManager.warning(
        error.request.toString(),
        'Error',
        3000,
        null,
        null,
        ''
      );
    } else {
      NotificationManager.warning(error.message, 'Error', 3000, null, null, '');
    }
  }
};

const updateUser = async (body) => {
  try {
    const res = await api.post(
      `${BASE_URL}/api/${JSON.parse(
        localStorage.getItem('userData')
      ).role.toLowerCase()}/${urls.updateUser}/${
        JSON.parse(localStorage.getItem('userData')).secretKey
      }/${JSON.parse(localStorage.getItem('userData')).id}`,
      body
    );
    if (res.data) {
      return Promise.resolve(res.data);
    } else {
      return Promise.reject(res.data);
    }
  } catch (error) {
    if (error.response) {
      NotificationManager.warning(
        error.response.data.message,
        'Error',
        3000,
        null,
        null,
        ''
      );
    } else if (error.request) {
      NotificationManager.warning(
        error.request.toString(),
        'Error',
        3000,
        null,
        null,
        ''
      );
    } else {
      NotificationManager.warning(error.message, 'Error', 3000, null, null, '');
    }
  }
};

const changePassword = async (body) => {
  try {
    const res = await api.post(
      `${BASE_URL}/api/${JSON.parse(
        localStorage.getItem('userData')
      ).role.toLowerCase()}/${urls.changePassword}`,
      body
    );
    if (res.data) {
      return Promise.resolve(res.data);
    } else {
      return Promise.reject(res.data);
    }
  } catch (error) {
    if (error.response) {
      NotificationManager.warning(
        error.response.data.message,
        'Error',
        3000,
        null,
        null,
        ''
      );
    } else if (error.request) {
      NotificationManager.warning(
        error.request.toString(),
        'Error',
        3000,
        null,
        null,
        ''
      );
    } else {
      NotificationManager.warning(error.message, 'Error', 3000, null, null, '');
    }
  }
};

const validateAuth = async (body) => {
  try {
    const res = await api.post(`${BASE_URL}/${urls.validateAuth}`, body);
    if (res.data) {
      return Promise.resolve(res.data);
    } else {
      return Promise.reject(res.data);
    }
  } catch (error) {
    if (error.response) {
      localStorage.removeItem('userData');
    } else if (error.request) {
      localStorage.removeItem('userData');
    } else {
      localStorage.removeItem('userData');
    }
  }
};

const postSchedule = async (body) => {
  try {
    const res = await api.post(
      `${BASE_URL}/api/${JSON.parse(
        localStorage.getItem('userData')
      ).role.toLowerCase()}/${urls.postSchedule}/${
        JSON.parse(localStorage.getItem('userData')).secretKey
      }/${JSON.parse(localStorage.getItem('userData')).id}`,
      body
    );
    if (res.data) {
      return Promise.resolve(res.data);
    } else {
      return Promise.reject(res.data);
    }
  } catch (error) {
    if (error.response) {
      NotificationManager.warning(
        error.response.data.message,
        'Error',
        3000,
        null,
        null,
        ''
      );
    } else if (error.request) {
      NotificationManager.warning(
        error.request.toString(),
        'Error',
        3000,
        null,
        null,
        ''
      );
    } else {
      NotificationManager.warning(error.message, 'Error', 3000, null, null, '');
    }
  }
};

const getSchedules = async (body) => {
  try {
    const res = await api.post(
      `${BASE_URL}/api/${JSON.parse(
        localStorage.getItem('userData')
      ).role.toLowerCase()}/${urls.getSchedules}/${
        JSON.parse(localStorage.getItem('userData')).secretKey
      }/${JSON.parse(localStorage.getItem('userData')).id}`,
      body
    );
    if (res.data) {
      return Promise.resolve(res.data);
    } else {
      return Promise.reject(res.data);
    }
  } catch (error) {
    if (error.response) {
      NotificationManager.warning(
        error.response.data.message,
        'Error',
        3000,
        null,
        null,
        ''
      );
    } else if (error.request) {
      NotificationManager.warning(
        error.request.toString(),
        'Error',
        3000,
        null,
        null,
        ''
      );
    } else {
      NotificationManager.warning(error.message, 'Error', 3000, null, null, '');
    }
  }
};

const getScheduleDetails = async (scheduleId) => {
  try {
    const res = await api.get(
      `${BASE_URL}/api/${JSON.parse(
        localStorage.getItem('userData')
      ).role.toLowerCase()}/${urls.getScheduleDetails}/${
        JSON.parse(localStorage.getItem('userData')).secretKey
      }/${JSON.parse(localStorage.getItem('userData')).id}/${scheduleId}`
    );
    if (res.data) {
      return Promise.resolve(res.data);
    } else {
      return Promise.reject(res.data);
    }
  } catch (error) {
    if (error.response) {
      NotificationManager.warning(
        error.response.data.message,
        'Error',
        3000,
        null,
        null,
        ''
      );
    } else if (error.request) {
      NotificationManager.warning(
        error.request.toString(),
        'Error',
        3000,
        null,
        null,
        ''
      );
    } else {
      NotificationManager.warning(error.message, 'Error', 3000, null, null, '');
    }
  }
};

const updateSchedule = async (body, scheduleId) => {
  try {
    const res = await api.post(
      `${BASE_URL}/api/${JSON.parse(
        localStorage.getItem('userData')
      ).role.toLowerCase()}/${urls.updateSchedule}/${
        JSON.parse(localStorage.getItem('userData')).secretKey
      }/${JSON.parse(localStorage.getItem('userData')).id}/${scheduleId}`,
      body
    );
    if (res.data) {
      return Promise.resolve(res.data);
    } else {
      return Promise.reject(res.data);
    }
  } catch (error) {
    if (error.response) {
      NotificationManager.warning(
        error.response.data.message,
        'Error',
        3000,
        null,
        null,
        ''
      );
    } else if (error.request) {
      NotificationManager.warning(
        error.request.toString(),
        'Error',
        3000,
        null,
        null,
        ''
      );
    } else {
      NotificationManager.warning(error.message, 'Error', 3000, null, null, '');
    }
  }
};

const deleteSchedule = async (scheduleId) => {
  try {
    const res = await api.get(
      `${BASE_URL}/api/${JSON.parse(
        localStorage.getItem('userData')
      ).role.toLowerCase()}/${urls.deleteSchedule}/${
        JSON.parse(localStorage.getItem('userData')).secretKey
      }/${JSON.parse(localStorage.getItem('userData')).id}/${scheduleId}`
    );
    if (res.data) {
      return Promise.resolve(res.data);
    } else {
      return Promise.reject(res.data);
    }
  } catch (error) {
    if (error.response) {
      NotificationManager.warning(
        error.response.data.message,
        'Error',
        3000,
        null,
        null,
        ''
      );
    } else if (error.request) {
      NotificationManager.warning(
        error.request.toString(),
        'Error',
        3000,
        null,
        null,
        ''
      );
    } else {
      NotificationManager.warning(error.message, 'Error', 3000, null, null, '');
    }
  }
};

const postMessage = async (body) => {
  try {
    const res = await api.post(
      `${BASE_URL}/api/${JSON.parse(
        localStorage.getItem('userData')
      ).role.toLowerCase()}/${urls.postMessage}/${
        JSON.parse(localStorage.getItem('userData')).secretKey
      }/${JSON.parse(localStorage.getItem('userData')).id}`,
      body
    );
    if (res.data) {
      return Promise.resolve(res.data);
    } else {
      return Promise.reject(res.data);
    }
  } catch (error) {
    if (error.response) {
      NotificationManager.warning(
        error.response.data.message,
        'Error',
        3000,
        null,
        null,
        ''
      );
    } else if (error.request) {
      NotificationManager.warning(
        error.request.toString(),
        'Error',
        3000,
        null,
        null,
        ''
      );
    } else {
      NotificationManager.warning(error.message, 'Error', 3000, null, null, '');
    }
  }
};

const getShiftInfo = async (body, shiftId) => {
  try {
    const res = await api.post(
      `${BASE_URL}/api/${JSON.parse(
        localStorage.getItem('userData')
      ).role.toLowerCase()}/${urls.getShiftInfo}/${
        JSON.parse(localStorage.getItem('userData')).secretKey
      }/${JSON.parse(localStorage.getItem('userData')).id}/${shiftId}`,
      body
    );
    if (res.data) {
      return Promise.resolve(res.data);
    } else {
      return Promise.reject(res.data);
    }
  } catch (error) {
    if (error.response) {
      NotificationManager.warning(
        error.response.data.message,
        'Error',
        3000,
        null,
        null,
        ''
      );
    } else if (error.request) {
      NotificationManager.warning(
        error.request.toString(),
        'Error',
        3000,
        null,
        null,
        ''
      );
    } else {
      NotificationManager.warning(error.message, 'Error', 3000, null, null, '');
    }
  }
};

const assignShift = async (shiftId, employeeId) => {
  try {
    const res = await api.get(
      `${BASE_URL}/api/${JSON.parse(
        localStorage.getItem('userData')
      ).role.toLowerCase()}/${urls.assignShift}/${
        JSON.parse(localStorage.getItem('userData')).secretKey
      }/${
        JSON.parse(localStorage.getItem('userData')).id
      }/${shiftId}/${employeeId}`
    );
    if (res.data) {
      return Promise.resolve(res.data);
    } else {
      return Promise.reject(res.data);
    }
  } catch (error) {
    if (error.response) {
      NotificationManager.warning(
        error.response.data.message,
        'Error',
        3000,
        null,
        null,
        ''
      );
    } else if (error.request) {
      NotificationManager.warning(
        error.request.toString(),
        'Error',
        3000,
        null,
        null,
        ''
      );
    } else {
      NotificationManager.warning(error.message, 'Error', 3000, null, null, '');
    }
  }
};

export {
  addDesignation,
  addDesignationToOrganization,
  addEmployee,
  addEmployeeToOrganization,
  addManagerToOrganization,
  addOrganizations,
  assignShift,
  changePassword,
  createShiftTemplate,
  deleteDesignations,
  deleteEmployee,
  deleteOrganization,
  deleteSchedule,
  deleteShiftTemplate,
  findDesignations,
  findDesignationsOfOrgnaization,
  findEmployees,
  findEmployeesOfOrgnaization,
  findManagersOfOrgnaization,
  findOrganizations,
  findShiftTemplates,
  getAllDesignations,
  getAllEmployee,
  getAllEmployeesByDesignationAndByOrganization,
  getAllOrganizations,
  getDesignation,
  getDesignations,
  getDesignationsByOrgnaization,
  getEmployee,
  getEmployeesByDesignation,
  getEmployeesByDesignationAndByOrganization,
  getHistoryShifts,
  getOrganization,
  getScheduleDetails,
  getSchedules,
  getShiftDetails,
  getShiftInfo,
  getShifts,
  getShiftTemplateDetail,
  getShiftTemplates,
  getUserDetails,
  postMessage,
  postSchedule,
  postShift,
  removeDesignationFromOrganization,
  removeEmployeeFromOrganization,
  removeManagerFromOrganization,
  updateDesignation,
  updateEmployee,
  updateOrganizations,
  updateSchedule,
  updateShiftTemplate,
  updateUser,
  validateAuth,
};
