const urls = {
  getDesignations: `getDesignations`,
  findOrganizations: 'findOrganizations',
  deleteOrganization: 'deleteOrganization',
  addOrganization: 'addOrganization',
  getOrganizationDetails: 'findOrganization',
  updateOrganization: 'updateOrganization',
  findDesignations: 'findDesignations',
  deleteDesignation: 'deleteDesignation',
  addDesignation: 'addDesignation',
  getDesignationDetails: 'findDesignation',
  updateDesignation: 'updateDesignation',
  findEmployees: 'findEmployees',
  addEmployee: 'addEmployee',
  getEmployeeDetails: 'findEmployee',
  updateEmployee: 'updateEmployee',
  deleteEmployee: 'deleteEmployee',
  findManagersOfOrgnaization: 'findManagersOfOrgnaization',
  findDesignationsOfOrgnaization: 'findDesignationsOfOrgnaization',
  findEmployeesOfOrgnaization: 'findEmployeesOfOrgnaization',
  getAllEmployees: 'getEmployees',
  addManagerToOrganization: 'addManagerToOrganization',
  removeManagerFromOrganization: 'removeManagerFromOrganization',
  getAllDesignations: 'getDesignations',
  getDesignationsByOrgnaization: 'getDesignationsByOrgnaization',
  getEmployeesByDesignationAndByOrganization:
    'getEmployeesByDesignationAndByOrganization',
  getAllEmployeesByDesignationAndByOrganization:
    'getAllEmployeesByDesignationAndByOrganization',
  addDesignationToOrganization: 'addDesignationToOrganization',
  removeDesignationFromOrganization: 'removeDesignationFromOrganization',
  addEmployeeToOrganization: 'addEmployeeToOrganization',
  removeEmployeeFromOrganization: 'removeEmployeeFromOrganization',
  getShiftTemplates: `getShiftTemplates`,
  getShiftTemplateDetail: `getShiftTemplate`,
  getAllOrganizations: `getOrganizations`,
  getEmployeesByDesignation: `getEmployeesByDesignation`,
  postShift: `postShift`,
  findShiftTemplates: `findShiftTemplates`,
  deleteShiftTemplate: `deleteShiftTemplate`,
  getShifts: `getShifts`,
  getShiftDetails: `getShift`,
  getHistoryShifts: `getHistoryShifts`,
  createShiftTemplate: `createShiftTemplate`,
  updateShiftTemplate: `updateShiftTemplate`,
  userDetails: `userDetails`,
  updateUser: `updateUser`,
  changePassword: `changePassword`,
  validateAuth: 'api/admin/validateAuth',
  postSchedule: 'postSchedule',
  getSchedules: 'getSchedules',
  getScheduleDetails: 'getSchedule',
  updateSchedule: 'updateSchedule',
  deleteSchedule: 'deleteSchedule',
  postMessage: 'postMessage',
  getShiftInfo: 'getShiftInfo',
  assignShift: 'assignShift',
};

export default urls;
