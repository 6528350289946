/* eslint-disable */
import axios from 'axios';
import { API_HEADER, BASE_URL } from './Config';

const api = axios.create({
  baseURL: BASE_URL,
  timeout: 120000,
  headers: API_HEADER,
});

export { api };
